import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import { Search,
         Form,
         Table,
         Button } from 'semantic-ui-react';
import { debounce, isEmpty } from 'lodash';

import withPagination       from 'hocs/withPagination';
import withSearch           from 'hocs/withSearch';
import connectResource      from 'utils/connectResource';
import formattedDate        from 'utils/formattedDate';
import { getPageAllById }   from 'utils/connectors';
import { sortByAttributes } from 'utils/sortComparators';
import { humanNamesFilter } from 'utils/recordFilters';
import { clients }          from 'resources/organizationResources';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const SEARCH_DEBOUNCE = 250;

const searchStyle = {
  margin: 0
};

const clientNamesFilter =
  humanNamesFilter('first_name', 'middle_name', 'last_name');

const clientSort = sortByAttributes('last_name', 'first_name');

function clientName({ first_name, middle_name, last_name }) {
  return [first_name, middle_name, last_name].filter(name => name).join(' ');
}

const clientResultRenderer = ({ title, dob }) => (
  <div>
    <strong>{ title }</strong>
    <div className='pull-right'>
      <small className='text-muted text-uppercase'>
        { formattedDate(dob, 'date') }
      </small>
    </div>
  </div>
);


const ClientRecord = ({ id, name, dob, onRemove }) => {
  return (
    <Table.Row>
      <Table.Cell><strong>{ name }</strong></Table.Cell>
      <Table.Cell>
        <small className='text-muted text-uppercase'>
          { formattedDate(dob, 'date') }
        </small>
      </Table.Cell>
      <Table.Cell textAlign='right'>
        <Button onClick={ () => onRemove(id) }
                id={ id }
                icon='cancel'
                basic
                color='red' />
      </Table.Cell>
    </Table.Row>
    );
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:          '',
      searchResults:  [],
    };

    this.handleSelect       = this.handleSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ 
        value:          '',
        searchResults:  [],
      });

      this.props.onResetClients();
    }
  }

  render() {
    const { disabled, loading, selectedClients, onRemoveClient } = this.props;
    const { searchResults, value } = this.state;

    return (
      <>
      <Form.Field>
          <Search disabled={ disabled }
                  results={ searchResults }
                  value={ value }
                  onResultSelect={ this.handleSelect }
                  onSearchChange={ this.handleSearchChange }
                  style={ searchStyle }
                  loading={ loading }
                  resultRenderer={ clientResultRenderer }
                  showNoResults={ false }
                  fluid />
      </Form.Field>
      
      { !isEmpty(selectedClients) &&
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Client Name</Table.HeaderCell>
              <Table.HeaderCell>DOB</Table.HeaderCell>
              <Table.HeaderCell textAlign='right'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              selectedClients.map((client) => {
                const name =  clientName(client);

                return (
                  <ClientRecord key={ client.id }
                                id= { client.id }
                                name={ name } 
                                dob={ client.dob } 
                                onRemove={ onRemoveClient }/>
                );
              })
            }
          </Table.Body>
        </Table>
      }
      </>
    );
  }

  handleSelect(evt, { result }) {
    const { data, onAddClient }        = this.props;
    const { clients }     = data;
    const { key }  = result;
    const client          = Object.values(clients)
                                  .find(({ id }) => id === key);

    this.setState({ value: '' });
    onAddClient(client);
  }

  handleSearchChange(evt, { value }) {
    const { onSearch } = this.props;
    onSearch({ query: value });

    this.setState({ value }, () => {   // we need to chain this as a callback to
      const { value } = this.state;    // ensure we are operating on the state
      this.updateSearchResults(value); // value we intend.
    });
  }

  updateSearchResults = debounce((value) => {
    const { data }      = this.props;
    const clients       = data.clients || {};

    const searchResults = Object
                          .values(clients)
                          .filter((client) => clientNamesFilter(value, client))
                          .sort(clientSort)
                          .map((client) => ({
                            key:    client.id,
                            title:  clientName(client),
                            dob:    client.dob
                          }));


    this.setState({ searchResults });
  }, SEARCH_DEBOUNCE, { leading: true });
  
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ClientList.propTypes = {
  disabled:         PropTypes.bool.isRequired,
  selectedClients:  PropTypes.array.isRequired,
  onAddClient:      PropTypes.func.isRequired,
  onRemoveClient:   PropTypes.func.isRequired,
  onResetClients:   PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

const connectedComponent = connectResource(ClientList, {
  key: ({ page, query }) => [page, query],
  connectors: {
    clients: getPageAllById(clients)
  }
});

export default withSearch(withPagination(connectedComponent));