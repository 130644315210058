import React                      from 'react';
import PropTypes                  from 'prop-types';
import { withRouter }             from 'react-router';
import { Switch, Route, Link }    from 'react-router-dom';
import { Button, List }           from 'semantic-ui-react';
import _                          from 'lodash';

import Privileges                 from 'constants/Privileges';
import SideBar                    from 'components/SideBar';
import SidebarToggle              from 'components/SidebarToggle';
import Main                       from 'components/Main';
import FullPage                   from 'components/FullPage';
import RecordList                 from 'components/RecordList';
import HeaderBar                  from 'components/HeaderBar';
import ToolBar                    from 'components/ToolBar';
import ManageRecordHeaderBar                    from 'components/ManageRecordHeaderBar';
import * as pathUtils             from 'utils/paths';
import { toSnakeCase }            from 'utils/strings';
import WithPermission             from 'containers/WithPermission';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const style = {
  toolBar: {
    borderRight:  '1px solid #fdfdfd',
    fontSize:     20
  },

  sideCard: {
    position:     'absolute',
    left:         '75%',
    width:        '25%',
    paddingLeft:  '1rem',
    overflowY:    'auto'
  },

  detailPaneOuter: {
    left:     '1rem',
    right:    '1rem',
    bottom:   0,
    top:      '1rem',
    position: 'absolute',
    overflow: 'auto'
  },

  detailPaneInner: {
    height:   '100%',
    position: 'absolute'
  }
};

// -----------------------------------------------------
// Helper Components
// -----------------------------------------------------

const DefaultListItem = ({ record }) => (
  <List.Header>{ record.name }</List.Header>
);

function sidebarClasses(sidebarOpened) {
  return  Object.keys(sidebarOpened).map(target =>
            `${target}-sidebar-${ sidebarOpened[target] ? 'opened' : 'closed'}`
          ).join(' ');
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class MasterDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reloading:        false,
      sidebarOpened: {
        left: true,
        right: true
      },
    };

    this.handleRefresh        = this.handleRefresh.bind(this);
    this.toggleSidebar        = this.toggleSidebar.bind(this);
  }

  render() {
    const {
      detailComponent:      Detail,
      formComponent:        Form,
      sideCardComponent:    SideCard,
      masterItemComponent:  MasterItemComponent,
      printable,
      importable,
      groupable,
      categorizable,
      versioned,
      manageOnNested,
      match,
      data,
      state,
      pending,
      records,
      resource,
      listSort,
      secondaryListSort,
      filterRecord,
      onUpdate,
      displayOnly,
      onPageNext,
      onPageReset,
      page,
      onSearch,
      onFilter,
      onCategory,
      category,
      query,
    } = this.props;

    const { reloading, sidebarOpened } = this.state;
    const resourcename = toSnakeCase(_.keys(data)[0]);
    const rootPath     = match.path;

    return (
      <FullPage className={`master-detail-component ${sidebarClasses(sidebarOpened)}`}>
        <SideBar className='master-pane printable-hidden' width={ sidebarOpened['left'] ? '20%' : '0%' }>
          <HeaderBar height='40px'>
            { sidebarOpened['left'] &&
            <ToolBar  offsetLeft='0px'
                      className='padded-content section-menu'
                      style={ style.toolBar } >

              {
                !displayOnly &&
                <WithPermission resource={ resource } actions={ Privileges.write }>
                  <Button as={ Link }
                          to={ pathUtils.newPath(match) }
                          color='black'
                          floated='right'
                          icon='add'
                          size='small'
                          circular
                          compact
                          basic />
                </WithPermission>
              }

              <Button color='black'
                      floated='right'
                      icon='refresh'
                      loading={ reloading || pending }
                      size='small'
                      circular
                      compact
                      onClick={ this.handleRefresh }
                      basic />

              <SidebarToggle direction='left'
                             target='left'
                             onClick={ this.toggleSidebar } />
            </ToolBar>
            }
          </HeaderBar>

          <RecordList loading={ pending }
                      records={ records }
                      resource={ resource }
                      listSort={ listSort }
                      secondaryListSort={ secondaryListSort }
                      filterRecord={ filterRecord }
                      listItemComponent={ MasterItemComponent || DefaultListItem }
                      offsetTop='40px'
                      onPageNext={ onPageNext }
                      onPageReset={ onPageReset }
                      page={ page }
                      onSearch={ onSearch }
                      onFilter={ onFilter }
                      onCategory={ onCategory }
                      categoriesByType={ data.categoriesByType }
                      categorizable={!!categorizable}
                      currentCategory={ category }
                      currentQuery={ query } />
        </SideBar>

        <Main offsetLeft={ sidebarOpened['left'] ? '20%' : '0%'} className='printable-full'>
          <HeaderBar height='40px' className='printable-hidden'>
            <ToolBar offsetLeft='0px' className='padded-content section-menu'>
              { !sidebarOpened['left'] &&
              <SidebarToggle direction='right'
                             target='left'
                             onClick={ this.toggleSidebar } />
              }
              <Switch>
                <Route path={ pathUtils.newRoute(match) } />
                <Route  path={ pathUtils.showRoute(match) }
                        exact={true}
                        render={({match}) => (

                  !displayOnly &&
                  <ManageRecordHeaderBar { ...this.props } match={match} rootPath={ rootPath } />
                )}/>

                <Route  path={ pathUtils.editRoute(match) }
                        exact={ true }
                        render={() => (

                  <Button as={ Link }
                          to='./'
                          color='red'
                          icon='cancel'
                          size='small'
                          circular
                          compact
                          basic />
                )}/>
                { manageOnNested &&
                <Route  path={ pathUtils.showNestedRoute(match) }
                        exact={true}
                        render={({match}) => (

                  <ManageRecordHeaderBar { ...this.props } match={match} rootPath={ rootPath } />
                )}/>
                }
              </Switch>
              {
                SideCard &&
                <SidebarToggle className='toggle-sidebar-right'
                               direction='right'
                               target='right'
                               onClick={ this.toggleSidebar } />
              }
            </ToolBar>
          </HeaderBar>

          <Main className='detail-pane padded-content printable-full' offsetTop='40px'>
            <div style={ style.detailPaneOuter }>
              <div className='detail-pane-main' style={ { ...style.detailPaneInner, width: SideCard ? '75%' : '100%' } }>
                <Switch>
                  <Route
                    path={pathUtils.editRoute(match)}
                    render={ (props) =>
                      <Form { ...props }
                        onUpdate={(record) => {
                          return this.props.onUpdate(record).then((res)=> {
                            if(!res.errors) {
                              this.props.history.push('./');
                            }
                          });
                        }}
                      />
                    } />

                  {/* creation route's component gets passed onCreate callback,
                   so path changes can be handled here and not be spread into that component */}

                  <Route
                    path={pathUtils.newRoute(match)}
                    render={props =>
                      <Form {...props}
                        onCreate={record => {
                          return this.props.onCreate(record).then(res => {
                            if(res.id) {

                              //navigate to newly created item
                              this.props.history.push(`./${res.id}`);
                            }

                            return res;
                          });
                        }}
                      />
                  }/>

                  <Route path={pathUtils.showRoute(match)} component={Detail} />
                </Switch>
              </div>
              {
                SideCard &&
                <div style={ style.sideCard } className='sidecard printable-hidden'>
                  {
                    SideCard &&
                    <Switch>
                    <Route  path={pathUtils.showNestedRoute(match)}
                            render={props => props.match.url !== pathUtils.newRoute(match) && (
                              <SideCard printable={!!printable}
                                        importable={!!importable}
                                        groupable={!!groupable}
                                        categorizable={!!categorizable}
                                        versioned={!!versioned}
                                        state={state}
                                        resource={resource}
                                        resourcename={resourcename}
                                        onUpdate={onUpdate}
                                        {...props} />
                            )} />
                    <Route  path={pathUtils.showRoute(match)}
                            render={props => props.match.url !== pathUtils.newRoute(match) && (
                              <SideCard printable={!!printable}
                                        importable={!!importable}
                                        groupable={!!groupable}
                                        categorizable={!!categorizable}
                                        versioned={!!versioned}
                                        state={state}
                                        resource={resource}
                                        resourcename={resourcename}
                                        onUpdate={onUpdate}
                                        {...props} />
                            )} />
                      </Switch>

                  }
                </div>
              }
            </div>
          </Main>
        </Main>
      </FullPage>
    );
  }

  async handleRefresh() {
    const { hardIndex } = this.props;

    this.setState({ reloading: true });

    if(hardIndex) {
      await hardIndex(null, {});
    }

    this.setState({reloading: false });
  }

  toggleSidebar(e, { floated }) {
    const { sidebarOpened } = this.state;

    const newSidebarOpened = {
      ...sidebarOpened,
      [floated]: !sidebarOpened[floated]
    };

    this.setState({ sidebarOpened: newSidebarOpened });
  }

}

// ---------------------------------------------------------
// PropTypes
// ---------------------------------------------------------

MasterDetail.propTypes = {
  masterItemComponent:  PropTypes.oneOfType([
                          PropTypes.func,
                          PropTypes.element,
                          PropTypes.elementType
                        ]),
  detailComponent:      PropTypes.oneOfType([
                          PropTypes.func,
                          PropTypes.element,
                          PropTypes.elementType
                        ]).isRequired,
  records:              PropTypes.array.isRequired,
  resource:             PropTypes.string,
  displayOnly:          PropTypes.bool,
  listSort:             PropTypes.func,
  secondaryListSort:    PropTypes.func,
  filterRecord:         PropTypes.func,
  onDelete:             PropTypes.func,
  onCreate:             PropTypes.func,
  onUpdate:             PropTypes.func,
  onClone:              PropTypes.func,
  onPageNext:           PropTypes.func,
  onPageReset:          PropTypes.func,
  disableEdit:          PropTypes.func,
  disableDelete:        PropTypes.func
};

MasterDetail.defaultProps = {
  displayOnly:    false,
  disableEdit:    (id) => !id,      // this is a touch obtuse compared to
  disableDelete:  (id) => !id       // false, but it quiets the linter.
};

// ---------------------------------------------------------
// Permissions Wrapper
// ---------------------------------------------------------

const PermissionWrapper = (props) => props.resource
  ? <WithPermission resource={ props.resource }
                    actions={ [Privileges.read, Privileges.write] }
                    anyRecord={ true }
                    redirect='/organization/permissiondenied'>
      <MasterDetail {...props} />
    </WithPermission>
  : <MasterDetail {...props} />;

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default withRouter(PermissionWrapper);
