import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { Form }             from 'semantic-ui-react';
import { deriveFieldSlug }  from 'utils/reportDefinitions';
import DateSelect           from 'components/DateSelect';
import moment               from 'moment';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const valuelessOperators = ['EMPTY', 'NEMPTY', 'TRUE', 'FALSE'];
const datePattern = /^\d{4}-\d{2}-\d{2}$/;

function isValidDate(dateString) {
  if (typeof dateString !== 'string') return false;
  if(!dateString.match(datePattern)) return false;

  const d = new Date(dateString);
  const dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date

  return d.toISOString().slice(0,10) === dateString;
}

function formatDate(value) {
  return moment(value).format('L');
}

function ValueInput({ value, valueLabel, op, onChange }) {
  if (valuelessOperators.includes(op)) return null;

  return isValidDate(value)
    ? <DateSelect value={ valueLabel } onChange={ (value) => onChange(null, {value}) } />
    : <Form.Input value={ valueLabel } onChange={ onChange } />;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class ComparisonExpression extends Component {
  constructor(props) {
    super(props);

    this.handleChange      = this.handleChange.bind(this);
  }

  render() {
    const { expression, editable=false, fieldsLookup } = this.props;
    const { field, form, field_name, op, value } = expression;

    let nameLabel   = field_name || deriveFieldSlug('field', field, form);
    let valueLabel  = (!editable && isValidDate(value)) ? formatDate(value) : value;

    if(fieldsLookup) {
      const fieldData = fieldsLookup[nameLabel];

      if(fieldData) {
        nameLabel   = fieldData.label;
        valueLabel  = fieldData.formatValue(valueLabel);
      }
    }

    return (
      <div className='conditional-comparison-expression'>
        <div className='conditional-comparison-operand'>{ nameLabel }</div>
        <div className='conditional-comparison-operator'> { op }</div>
        <div className='conditional-comparison-operand ui form'>
          { editable
            ? <ValueInput value={ value }
                          valueLabel={ valueLabel || '' }
                          op={ op }
                          onChange={ this.handleChange } />
            : valueLabel
          }
        </div>
      </div>
    );
  }

  handleChange(evt, { value }) {
    const { expression, index, onChange } = this.props;

    const newExpression = {
      ...expression,
      value
    };

    onChange({ index, expression: newExpression });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ComparisonExpression.propTypes = {
  expression: PropTypes.shape({
    field_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    op:         PropTypes.string,
    value:      PropTypes.any
  }).isRequired,

  editable: PropTypes.bool,

  fieldsLookup: PropTypes.objectOf(PropTypes.shape({
    label:        PropTypes.string,
    formatValue:  PropTypes.func
  }))
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ComparisonExpression;
