import React, { Component }             from 'react';
import PropTypes                        from 'prop-types';
import { Modal, Button, Header, Form }  from 'semantic-ui-react';

import ConditionalEditor, {
       ConditionGroup,
       Item,
       Registry,
       GroupProvider }                  from '@coactionnet/conditional-editor';
import FieldProvider                    from 'utils/conditions/providers/FieldProvider';
import ConditionDateSelect              from 'components/ConditionDateSelect';
import ConditionTimeSelect              from 'components/ConditionTimeSelect';

import uniqueId                         from 'utils/uniqueId';

// --------------------------------------------------------
// Helpers
// --------------------------------------------------------

// providers
function getFieldAdapter() {
  const p = new FieldProvider(Item, 'Condition');
  return p.getAdapter();
}
function getGroupAdapter() {
  const p = new GroupProvider(ConditionGroup, 'Condition Group');
  return p.getAdapter();
}


// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

class ConditionModal extends Component {

  // construction
  constructor(props) {
    super(props);

    const { conditions } = this.props;

    this.registry = new Registry();
    this.registry.add(getFieldAdapter());
    this.registry.add(getGroupAdapter());

    this.state  = { conditions };
    this.formId = uniqueId();

    this.handleSubmit           = this.handleSubmit.bind(this);
    this.handleConditionChange  = this.handleConditionChange.bind(this);
  }

  // event handlers
  handleConditionChange(conditions) {
    this.setState({ conditions });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const { onSubmit, onClose } = this.props;
    const { conditions }        = this.state;

    onSubmit(conditions);
    onClose();
  }

  // rendering
  render() {
    const { fields, opened, title, onClose }  = this.props;
    const { conditions }                      = this.state;

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='pencil' content={ title } />
        <Modal.Content>
          <Form onSubmit={ this.handleSubmit } id={ this.formId }>
            <Header as='h3'>Conditions</Header>
            <div className='conditions-editor'>
              <ConditionalEditor  conditions={ conditions }
                                  fields={ fields }
                                  registry={ this.registry }
                                  valuesDateControl={ ConditionDateSelect }
                                  valuesTimeControl={ ConditionTimeSelect }
                                  onChange={ this.handleConditionChange } />
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ onClose } negative basic>Cancel</Button>
          <Button type='submit' form={ this.formId } positive basic>Apply</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ConditionModal.propTypes = {
  conditions:   PropTypes.object.isRequired,
  fields:       PropTypes.array.isRequired,
  opened:       PropTypes.bool.isRequired,
  title:        PropTypes.string.isRequired,
  onSubmit:     PropTypes.func.isRequired,
  onClose:      PropTypes.func.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ConditionModal;
