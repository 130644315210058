import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { Form, Grid, Header }   from 'semantic-ui-react';

import ConditionalEditor, {
       ConditionGroup,
       Control,
       Item,
       Registry,
       GroupProvider }  from '@coactionnet/conditional-editor';

import ConditionDateSelect                from 'components/ConditionDateSelect';
import ConditionTimeSelect                from 'components/ConditionTimeSelect';
import { systemFieldsWithUserOptions }    from 'constants/reports/SystemFields';
import indexOnAttribute                   from 'utils/indexOnAttribute';
import { shapeField, shapeCalculations }  from 'utils/formLogic/conditionEditor';
import { reportFormIds,
         deriveFieldSlug }                  from 'utils/reportDefinitions';

import ReportConditionProvider  from 'utils/conditions/providers/ReportConditionProvider';

import hocFieldSelect from './hocFieldSelect';

import 'style/ConditionEditorOverrides.css';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const excludedFieldTypes = ['hyperlink', 'presentation'];

function getGroupAdapter() {
  const p = new GroupProvider(ConditionGroup, 'Condition Group');
  return p.getAdapter();
}

function getItemAdapter() {
  const p = new ReportConditionProvider(Item, 'Condition');
  return p.getAdapter();
}

// Returns a list of fields, used to supply options to the conditions editor.
function getShapedFields(forms, fields, users, reportDefinition) {
  const formIds       = reportFormIds(reportDefinition);
  const indexedFields = indexOnAttribute(fields, 'id');
  const indexedForms  = indexOnAttribute(forms, 'id');

  let formFields = [];
  if (reportDefinition.report_type !== 'fields'){
    formFields  = formIds
                      .map(id => indexedForms[id])
                      .flatMap(({ id: formId, field_ids }) => (
                        field_ids
                        .map((fieldId) => (
                          shapeField({
                            ...indexedFields[fieldId],
                            id:   deriveFieldSlug('field', fieldId, formId),
                            form: formId
                          })
                        ))
                        .filter(({ fieldType }) => !excludedFieldTypes.includes(fieldType))
                      ));
  } else {
    formFields  = reportDefinition.fields
                      .filter(({type, field}) => type === 'field' && Number.isInteger(field))
                      .map(({field}) => (
                        shapeField({
                          ...indexedFields[field],
                          id:   deriveFieldSlug('field', field, null)
                        })
                      ))
                      .filter(({ fieldType }) => !excludedFieldTypes.includes(fieldType));
  }

  const systemFields =
    systemFieldsWithUserOptions(reportDefinition, users, formIds);

  const typeSystemFields = systemFields.map((systemField) => (
    shapeField({
      ...systemField,
      id: deriveFieldSlug('field', systemField.id, systemField.form)
    })
  ));

  return [...formFields, ...typeSystemFields];
}

function getShapedCalculations(formCalculations, reportDefinition) {
  const reportCalculationsIds = reportDefinition.fields
                              .filter(({type}) => type === 'calculation')
                              .map(({field}) => field);

  return  Object
          .entries(formCalculations)
          .flatMap(([formId, calculations]) => (
            shapeCalculations(calculations, false)
            // On Fields reports show only calculations that were selected
            .filter(({value}) => reportDefinition.report_type === 'fields' ? reportCalculationsIds.includes(value) : true )
            .map((shapedCalculation) => (
              {
                ...shapedCalculation,
                value:  deriveFieldSlug(
                          'calculation',
                          shapedCalculation.value,
                          formId
                        ),
                form: formId
              }
            ))
          ));
}

function ValueSelect(props) {
  return (
    <Control.SelectInput { ...props } includeBlankOption={true} />
  );
}

// While null conditions are valid, we need a default structure to render the
// conditional editor.  Without this, nothing is rendered.
const defaultConditions = { and: [] };

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class FilterSelection extends Component {
  constructor(props) {
    super(props);

    this.registry = new Registry();
    this.registry.add(getItemAdapter());
    this.registry.add(getGroupAdapter());

    this.handleChange  = this.handleChange.bind(this);
  }

  render() {
    const { fields,
            filters,
            forms,
            users,
            formCalculations,
            reportDefinition } = this.props;

    const conditions    = filters || reportDefinition.conditions || defaultConditions;
    const shapedFields  = getShapedFields(forms, fields, users, reportDefinition);

    const shapedCalculations  = getShapedCalculations(formCalculations, reportDefinition);
    const fieldOptions        = [...shapedFields, ...shapedCalculations];

    const FieldSelect = hocFieldSelect(forms);

    return (
      <Form>
        {
          this.props.children ?
           this.props.children :
           <Header as='h2'>Select Report Filters</Header>
        }
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <div className='conditions-editor'>
                <ConditionalEditor  conditions={ conditions }
                                    registry={ this.registry }
                                    fields={ fieldOptions }
                                    fieldsControl={ FieldSelect }
                                    valuesDateControl={ ConditionDateSelect }
                                    valuesTimeControl={ ConditionTimeSelect }
                                    valuesSelectOneControl={ ValueSelect }
                                    onChange={ this.handleChange } />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  handleChange(conditions) {
    const { onChange, filterType=null } = this.props;

    onChange({
      conditions,
      filterType: filterType ? filterType : undefined });
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

FilterSelection.defaultProps = {
  reportDefinition:   {},
  forms:              [],
  fields:             [],
  users:              [],
  filters:            null,
  formCalculations:   {}
};

FilterSelection.propTypes = {
  reportDefinition:   PropTypes.object,
  forms:              PropTypes.array,
  fields:             PropTypes.array,
  users:              PropTypes.array,
  formCalculations:   PropTypes.object,
  filters:            PropTypes.object,
  onChange:           PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default FilterSelection;
