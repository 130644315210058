import React, { useState, useEffect }                from 'react';
import { Header, Segment, Icon, Container, Message, Button, Grid, }  from 'semantic-ui-react';
import { Link }                                     from 'react-router-dom';

import Privileges                 from 'constants/Privileges';
import { contentNamespace }       from 'utils/content';
import formattedDate              from 'utils/formattedDate';
import formattedAge               from 'utils/formattedAge';
import WithPermission             from 'containers/WithPermission';
import DataList                   from 'components/DataList';
import ClientResource             from 'resources/organization/ClientResource';
import MergeClientsResource       from 'resources/organization/MergeClientsResourceResource';
import useOrganizationResource    from 'hooks/useOrganizationResource';
import useOrganizationFetcher     from 'hooks/useOrganizationFetcher';

import ClientLinkModal from 'caseWorthy/components/ClientLinkModal';

import Fields     from '../Fields';
import Forms      from '../Forms';
import MergeLink  from './MergeLink';
import ProfilePhoto from './ProfilePhoto';
import Household from './Household';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const genderLabels = contentNamespace('organization.clients.gender');
function getClientDetails({ id, dob, dod, gender, active }) {

  const details = [
    { label: 'Client ID', value: id },
    { label: 'DOB',       value: formattedAge(dob, dod) },
    { label: 'Gender',    value: genderLabels(gender) }
  ];

  if (dod !== null) {
    details.push( { label: 'Date Deceased', value: formattedDate(dod, 'date', '') });
  }

  if (!active) {
    details.push( { label: 'Status', value: 'Inactive' } );
  }

  return details;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DetailView = (props) => {
  const { history, match: { params: { id } } } = props;

  const initialClient       = useOrganizationResource(ClientResource.detailShape(), { id });
  const merge               = useOrganizationFetcher(MergeClientsResource.createShape());
  const [opened, setOpened] = useState(false);
  const [client, setClient] = useState(initialClient);

  const { first_name,
          middle_name,
          last_name,
          photo_url,
          has_duplicates,
          referred_not_linked,
          duplicates=[] } = client;

  const fullName            = [first_name || '...', middle_name, last_name].join(' ');
  const dataListItems       = getClientDetails(client);

  useEffect(() => {
    setClient(initialClient);
  }, [initialClient, setClient]);

  useEffect(() => {
    setOpened(referred_not_linked);
  }, [referred_not_linked, setOpened]);

  const handleMergeSave = async ({ masterId, mergedIds, is_duplicate=true }, done) => {
    const { match: { params: { id } } } = props;

    const data = {
      master_client_id: masterId,
      client_ids:       mergedIds,
      is_duplicate:     is_duplicate
    };

    const response = await merge({}, data);

    if(!response.errors) {
      if (id === masterId) {
        done();
        window.location.reload(true);
      } else {
        history.push(`/organization/clients/${masterId}`);
        window.location.reload(true);
      }
    }
  };

  const handleClick = (evt) => {
    evt.preventDefault();
    setOpened(true);
  };

  const handleClose = (result) => {
    if(result === null || result === undefined) {
      setOpened(false);
    } else {
      if(!result.errors) {
        setClient(result);
      }
    }
  };

  return (
    <Container>
      { id
        && <ClientLinkModal client={ client }
                            opened={ opened }
                            dataList={ [{ label: 'Name', value: fullName }, ...dataListItems] }
                            onClose={ handleClose } />
      }

      <Segment loading={ !client }>
        <Grid>
          <Grid.Column width={4}>
            { id && <ProfilePhoto id={ id } photoUrl={ photo_url } /> }
          </Grid.Column>

          <Grid.Column width={12}>
            <Header as='h2'>
              <Link to={ `/organization/clients/${id}`}>
                { fullName }
              </Link>
            </Header>
            {
              id
              && <DataList items={ dataListItems } />
            }
          </Grid.Column>
        </Grid>

        {
          has_duplicates &&
          <Message warning icon>
            <Icon name='exclamation' color='yellow' />
            <Message.Content>
              <Message.Header>
                This client appears to have a duplicate record.  Would you like
                to&nbsp;
                <MergeLink  client={ client }
                            duplicates={ duplicates }
                            onSubmit={ handleMergeSave }>
                  merge these records
                </MergeLink>?
              </Message.Header>
            </Message.Content>
          </Message>
        }

        {
          referred_not_linked &&
          <Message error icon>
            <Icon name='exclamation' color='red' />
            <Message.Content>
              <p>
                This client has pending DTD referrals, but the referrals
                cannot be sent until the client has been linked to the DTD system.
              </p>
              <Button onClick={ handleClick }
                      size='tiny'
                      color='red'>
                Link Client Record
              </Button>
            </Message.Content>
          </Message>
        }
      </Segment>

      { id && props.match.isExact &&
        <>
          <Fields client={ client } />
          <Household client={ client } />
        </>
      }

      <WithPermission resource='client_forms'
                      actions={ [Privileges.read, Privileges.write] }
                      anyRecord={ true }>

        <Forms { ...props } />
      </WithPermission>
    </Container>
  );
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DetailView;
