import React, { useState, useRef }  from 'react';
import PropTypes                    from 'prop-types';
import { withRouter }               from 'react-router';
import { NavLink }                  from 'react-router-dom';
import { List, Input }            from 'semantic-ui-react';
import urljoin                      from 'url-join';
import _                            from 'lodash';

import Main from './Main';
import { FilterButton, FilterList } from './RecordListFilters';
import { CategoryButton, CategorySelect } from './RecordListCategories';

// -----------------------------------------------------
// Helpers / Constants
// -----------------------------------------------------

const SEARCH_DEBOUNCE     = 250;
const PAGINATION_DEBOUNCE = 250;
const emptyFilters = { text: null, duplicates: false, inactive: false };

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const RecordList = ({ listItemComponent: ListItem,
                      className,
                      offsetLeft,
                      offsetRight,
                      offsetTop,
                      offsetBottom,
                      onPageNext,
                      onPageReset,
                      onSearch,
                      onFilter,
                      filterRecord,
                      records,
                      onCategory,
                      listSort,
                      secondaryListSort,
                      resource,
                      match,
                      categoriesByType,
                      categorizable,
                      currentCategory,
                      currentQuery }) => {

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(emptyFilters);
  const [showCategories, setShowCategories] = useState(false);
  const [, setCategory] = useState(currentCategory);
  const scrollerEl            = useRef(null);

  const titleResourceName = _.startCase(_.toLower(resource));
  const filteredRecords   = records
                            .filter(rec => filterRecord(filters.text, rec))
                            .sort(listSort)
                            .sort(secondaryListSort); // necessary only when applying an opposite direction

  const handleScroll = _.throttle(() => {
    const { clientHeight, scrollTop, scrollHeight } = scrollerEl.current;

    if(scrollHeight - scrollTop <= clientHeight) {
      onPageNext();
    }
  }, PAGINATION_DEBOUNCE);

  const applySearch = _.debounce((page, query) => {
    onPageReset && onPageReset();
    onSearch    && onSearch({ query });
  }, SEARCH_DEBOUNCE);

  const handleSearch = (evt, { value }) => {
    setFilters({ ...filters, text: value });
    applySearch(1, value);
  };

  const applyFilters = _.debounce((page, filters) => {
    onPageReset && onPageReset();
    onFilter    && onFilter({ filters });
    console.log('filters:',filters);
  }, SEARCH_DEBOUNCE);

  const handleFilter = (filter) => {
    const newFilters = { ...emptyFilters, [filter]: true };
    setFilters(newFilters);
    applyFilters(1, newFilters);
  };

  const handleShowFilters = () => {
    if(showFilters) {
      setFilters(emptyFilters);
      applyFilters(1, emptyFilters);
    }
    setShowFilters(!showFilters);
  };

  const filterAction = FilterButton(onFilter, showFilters, handleShowFilters);

  const applyCategory = _.debounce((page, category) => {
    onPageReset && onPageReset();
    onCategory  && onCategory({ category });
  }, SEARCH_DEBOUNCE);

  const handleCategory = (category) => {
    const newCategory = category;
    setCategory(newCategory);
    applyCategory(1, newCategory);
  };

  const handleShowCategory = () => {
    if(showCategories) {
      setCategory(currentCategory);
      applyCategory(1, currentCategory);
    }
    setShowCategories(!showCategories);
  };

  CategoryButton(onCategory, showCategories, handleShowCategory);

  return (
    <Main className={ `${className} record-list-container` }
          offsetLeft={ offsetLeft }
          offsetRight={ offsetRight }
          offsetTop={ offsetTop }
          offsetBottom={ offsetBottom }>

      { categorizable && CategorySelect(categoriesByType, handleCategory, currentCategory) }

      <Input  icon='search'
              iconPosition='left'
              type='search'
              placeholder={`Search ${titleResourceName}...`}
              value={ filters.text || currentQuery }
              onChange={ handleSearch }
              action={ filterAction }
              autoFocus />
      { showFilters && FilterList(filters, handleFilter) }

      <section className="record-list-scroller" ref={scrollerEl} onScroll={ handleScroll }>
        {
          filteredRecords.length > 0
          ? <List className='record-list-component' divided>
              {
                filteredRecords.map(record => (
                  <List.Item  className='record-list-item-component'
                              as={ NavLink }
                              key={ record.id }
                              to={ urljoin(match.path, record.id) }>
                    <ListItem record={ record } />
                  </List.Item>
                ))
              }
            </List>
          : <div className="record-list-message">
              { _.some(filters)
                ? `No ${titleResourceName} Matched Your Filters`
                : `No ${titleResourceName} Present`
              }
            </div>
        }
      </section>
    </Main>
  );
};

RecordList.defaultProps = {
  className:    '',
  offsetLeft:   '0px',
  offsetRight:  '0px',
  offsetTop:    '0px',
  offsetBottom: '0px',
  listSort:     (a, b) => 0,    // eslint-disable-line no-unused-vars
  secondaryListSort: (a, b) => 0,    // eslint-disable-line no-unused-vars
  filterRecord: () => true,
  categoryRecord: () => true,
  categoriesByType: () => []
};

RecordList.propTypes = {
  listItemComponent:  PropTypes.func.isRequired,
  records:            PropTypes.array.isRequired,
  listSort:           PropTypes.func,
  filterRecord:       PropTypes.func,
  categoryRecord:     PropTypes.func,
  resource:           PropTypes.string,
  offsetLeft:         PropTypes.string,
  offsetRight:        PropTypes.string,
  offsetTop:          PropTypes.string,
  offsetBottom:       PropTypes.string,
  className:          PropTypes.string,
  onPageNext:         PropTypes.func,
  onSearch:           PropTypes.func,
  categoriesByType:   PropTypes.array,
  categorizable:      PropTypes.bool,
  currentCategory:    PropTypes.number,
};

// --------------------------------------------------------
// Public API
// --------------------------------------------------------

export default withRouter(RecordList);


