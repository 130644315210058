import Ajv from 'ajv';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------
const formFieldExpr = /^(\w+?)_(\w+?)(?:_(\d+))?$/;

const matcher = (new Ajv()).compile({
  oneOf: [
    {
      allOf: [
        {
          type: 'object',
          properties: {
            op: {
              type: 'string',
              enum: [
                'EQ',
                'NEQ',
                'IN',
                'NIN',
                'LT',
                'LTE',
                'GT',
                'GTE',
                'BWITH',
                'EWITH',
                'CONTAINS',
                'NCONTAINS',
                'RANGE',
                'NRANGE',
                'DOW',
                'NDOW'
              ]
            },
            value: { type: ['number', 'string', 'boolean', 'array', 'null'] },
            type:  { type: 'string' }
          }
        }, {
          anyOf: [
            {
              required: ['form', 'field', 'op', 'value', 'type'],
              properties: {
                field:  { type: ['string', 'number'] },
                form:   { type: ['string', 'number'] },
              }
            }, {
              required: ['field', 'op', 'value', 'type'],
              properties: {
                field:  {
                  oneOf: [
                    {
                      'type': 'number'
                    }, {
                      type: 'string',
                      pattern: '^(client|form|user|response|household)_\\w+$'
                    }, {
                      type: 'string',
                      pattern: '^(created_by|created_at|updated_by|updated_at|completed_at)$'
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }, {
      allOf: [
        {
          type: 'object',
          required: [ 'field', 'op', 'type'],
          properties: {
            op:     { type: 'string', enum: ['EMPTY', 'NEMPTY', 'TRUE', 'FALSE'] },
            type:   { type: 'string' }
          }
        }, {
          anyOf: [
            {
              required: ['form'],
              properties: {
                field:  { type: ['string', 'number'] },
                form:   { type: ['string', 'number'] }
              }
            }, {
              properties: {
                field:  {
                  oneOf: [
                    {
                      'type': 'number'
                    }, {
                      type: 'string',
                      pattern: '^(client|form|user|response|household)_\\w+$'
                    }, {
                      type: 'string',
                      pattern: '^(created_by|created_at|updated_by|updated_at|completed_at)$'
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }, {
      type: 'object',
      required: [ 'field', 'form', 'op', 'type'],
      properties: {
        op:     { type: 'string', enum: ['TRUE', 'FALSE'] },
        type:   { type: 'string' },
        field:  { type: 'string', pattern: '^client_link$' },
        form:   { type: ['string', 'number' ] }
      }
    }
  ]
});

function transformIn({ form, field, op, value, type }) {
  const field_name_components = [type, field];

  if(form) {
    field_name_components.push(form);
  }

  const field_name = field_name_components.join('_');

  return { field_name, op, value };
}

function transformOut({ field_name, op, value }) {
  const [, type, fieldName, form=undefined] = formFieldExpr.exec(field_name);
  const field = isNaN(fieldName) ? fieldName : parseInt(fieldName, 10);

  return { form, field, op, value, type };
}

// -----------------------------------------------------
// Class Definition
// -----------------------------------------------------

class ReportConditionProvider {
  constructor(node, name, providesControl=true) {
    this.adapter = {
      matcher,
      transformIn:  transformIn,
      transformOut: transformOut,
      create:       () => ({ form: '', field: '', op: 'EQ', value: '', type: '' }),
      node,
      name,
      providesControl
    };
  }

  getAdapter() {
    return this.adapter;
  }
}

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ReportConditionProvider;
